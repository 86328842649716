import { map, Observable } from 'rxjs';
import { ApiResponse, ConsumptionPod, ProductionPod } from '../shared/interfaces';
import { DomesticTradePartnerResponse, PodResponse } from '../shared/interfaces/responses';
import { environment } from '../../environments/environment';
import { ScheduleType } from '../shared/enums';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filterOutNullishProperties } from '../shared/utils';

/**
 * Responsible to handle master data related operations, like fetching PODs or domestic partner EIC codes.
 */
@Injectable()
export class MasterDataService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Lists all currently active PODs (Points of Delivery) that match the specified filters. This function is useful for
   * retrieving a tailored list of active PODs (and sub-PODs) based on specific criteria such as tenant, and schedule type.
   *
   * @param params - An object containing the filters to apply when listing PODs.
   * @param [params.tenant] - Optional. Filter to return only PODs assigned to a specific tenant.
   * If omitted, PODs for all tenants are returned.
   * @param [params.scheduleType] - Optional. Filter to return only PODs that operate under a specific schedule type
   * ({@link A01} or {@link A04}). If omitted, PODs with any schedule type are included.
   * @param [params.includeSubPods] - Optional. Specifies whether to include sub-PODs in the list.
   * If set to true, sub-PODs belonging to active aggregator PODs are also included. Defaults to false.
   * @param [params.active] - Optional. If it is false, the validation time does not matter.
   * @param [params.date] - Optional. The date what we want to query.
   *
   * @returns An Observable emitting an array of {@link PodResponse} objects.
   * Each object in the array represents an active POD that matches the specified filters.
   */
  public listPods(params?: {
    tenant?: string;
    scheduleType?: ScheduleType.A01 | ScheduleType.A04;
    includeSubPods?: boolean;
    active?: boolean;
    date?: string;
    assignmentFrom?: string;
    assignmentUntil?: string;
  }): Observable<PodResponse[]> {
    params = params ?? {};
    params.includeSubPods = params?.includeSubPods ?? false;

    return this.http
      .get<ApiResponse<PodResponse[]>>(`${environment.apiBasePath}/v1/platform/master-data/pods`, {
        params: filterOutNullishProperties({
          tenant: params.tenant,
          schedule_type: params.scheduleType,
          include_sub_pods: params.includeSubPods,
          active: params.active,
          date: params.date,
          assignment_from: params.assignmentFrom,
          assignment_until: params.assignmentUntil,
        }),
      })
      .pipe(map(response => response?.payload ?? []));
  }

  /**
   * Lists all PODs belong to the tenant, grouped by their schedule type.
   *
   * @param tenantIdentifier The unique identifier of the tenant.
   * @param date The optional selected date in `yyyy-MM-dd` format.
   */
  public listGroupedPodsForTenant(
    tenantIdentifier: string,
    date?: string
  ): Observable<{
    productionPods: ProductionPod[];
    consumptionPods: ConsumptionPod[];
  }> {
    return this.listPods({ tenant: tenantIdentifier, date }).pipe(
      map(pods => {
        const productionPods: ProductionPod[] = [];
        const consumptionPods: ConsumptionPod[] = [];

        pods.forEach(pod => {
          if (pod.scheduleType === ScheduleType.A01) {
            productionPods.push(pod as ProductionPod);
          } else if (pod.scheduleType === ScheduleType.A04) {
            consumptionPods.push(pod as ConsumptionPod);
          }
        });

        return { productionPods, consumptionPods };
      })
    );
  }

  /**
   * Lists all domestic trade partners for the given params.
   *
   * @param params Optional filters:
   * - tenant: The unique identifier of the tenant.
   * - forwardTradeAllowed: Flag for partners those have or do not have forward trade allowed.
   */
  public listDomesticTradePartners(params?: {
    tenant?: string;
    forwardTradeAllowed?: boolean;
  }): Observable<DomesticTradePartnerResponse[]> {
    return this.http
      .get<ApiResponse<DomesticTradePartnerResponse[]>>(
        `${environment.apiBasePath}/v1/platform/master-data/domestic-trade-partners`,
        {
          params: filterOutNullishProperties({
            ...params,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            forward_trade_allowed: params?.forwardTradeAllowed,
          }),
        }
      )
      .pipe(map(response => response?.payload ?? []));
  }
}
