<app-dialog-layout class="assign-user__app-dialog-layout" [closeable]="false">
  <section name="title">{{ data.title ?? 'Assign new value' }}</section>
  <app-banner-container></app-banner-container>
  <div class="assign-user-container">
    <span>{{ data.description ?? 'Give a value to assign it.' }}</span>
    <form [formGroup]="formGroup">
      <mat-form-field [class]="'autocomplete'">
        <mat-label>{{ data.label ?? 'Value' }}</mat-label>
        <input matInput formControlName="valueControl" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option [value]="option.text" [disabled]="option.disabled" *ngFor="let option of filteredOptions | async">
            {{ option.text }}
            {{
              option.disabled
                ? option.active === false
                  ? ' (inactive, assigned)'
                  : ' (assigned)'
                : option.active === false
                  ? ' (inactive)'
                  : ''
            }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="formGroup.get('valueControl')?.invalid">{{
          getErrorMessage(formGroup.get('valueControl'))
        }}</mat-error>
      </mat-form-field>
      @if (data.pod !== undefined) {
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Validity range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="rangeStart" placeholder="Range start" />
            <input matEndDate formControlName="rangeEnd" placeholder="Range end" />
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      }
    </form>
  </div>
  <section name="footer" class="assign-user__actions">
    <button mat-button (click)="confirm(false)" [disabled]="requestInFlight">Cancel</button>
    <button
      mat-button
      color="primary"
      (click)="confirm(true)"
      [disabled]="
        (data.pod !== undefined ? formGroup.invalid : formGroup.get('valueControl')?.invalid) || requestInFlight
      "
    >
      Confirm
      <mat-spinner *ngIf="requestInFlight" [diameter]="20"></mat-spinner>
    </button>
  </section>
</app-dialog-layout>
