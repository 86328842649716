import Handsontable from 'handsontable';
import { CellChange } from 'handsontable/common';
import { Subject } from 'rxjs';

/**
 * Sets the next values of the column summation subjects of the columns of changed cells.
 *
 * @param changes - array of cell changes
 * @param handsonTable - the handsontable instance
 * @param quantityStartRow - index of the row where the summable quantities start
 * @param columnSummationSubjects - the column summation subjects that signal when a value in the column is changed
 * @param mapPropertyToCol - optional function to map the column name to the column index
 */
export function setValuesInColumnSummationRow(
  changes: CellChange[] | null,
  handsonTable: Handsontable,
  quantityStartRow: number,
  columnSummationSubjects: Map<number | string, Subject<number | null>>,
  mapPropertyToCol?: (property: string) => number
): void {
  changes
    ?.filter(change => change !== null && change[0] >= quantityStartRow && !isNaN(change[3] as number))
    .forEach(change => {
      const column = mapPropertyToCol === undefined ? change[1] : mapPropertyToCol(change[1] as string);

      const columnValues = (
        Array.from(columnSummationSubjects.keys()).every(key => typeof key === 'number')
          ? handsonTable.getDataAtCol(column as number).slice(quantityStartRow)
          : handsonTable.getDataAtProp(column as string).slice(quantityStartRow)
      ) as number[];
      const isNullColumn = columnValues.every(value => value === null);
      const columnSum = columnValues.reduce((sum, value) => sum + (value ?? 0), 0);

      columnSummationSubjects.get(column as number | string)?.next(isNullColumn ? null : columnSum);
    });
}
