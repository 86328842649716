/**
 * Specifies the roles that can be assigned to users. The authorization for the application is based on these roles.
 */
export enum Role {
  /** Administrator role. */
  Administrator = 'administrator',

  /**  Allows the user to read public orders. */
  HupxPublicOrderBooks = 'hupx::public-order-books',

  /** Allows the user to create retail orders. */
  HupxCreateOrderRetail = 'hupx::create-order-retail',

  /** Allows the user to create orders. */
  HupxCreateOrder = 'hupx::create-order',

  /** Indicates that the user is an external partner. */
  HupxExternalPartner = 'hupx::external-partner',

  /** Allows the user to fetch trade history of all users. */
  HupxTradeHistory = 'hupx::trade-history',

  /** Allows the user to use the order validation feature. */
  HupxOrderValidation = 'hupx::order-validation',

  /** Allows the user to use the trade overview feature. */
  HupxReport = 'hupx::report',

  /** Allows the user to use the forward trade feature. */
  HupxForwardTrade = 'hupxdam::forward-trade',

  /** Allows the user to list and modify the DAM deadline of each tenant. */
  HupxDamDeadlineConfiguration = 'hupxdam::deadline-configuration',

  /** Allows the user to list and modify the IDA deadlines of each tenant. */
  HupxIdaDeadlineConfiguration = 'hupxida::deadline-configuration',

  /** Allows the user to read Power BI reports. */
  PowerBi = 'power-bi::read',

  /** Allows the user to read the EDA offer reports. */
  EdaReport = 'eda::report',

  /** Allows the user to use the EDA pricer feature. */
  EdaStrategy = 'eda::strategy',

  /** Allows the user to use the EDA configuration feature. */
  EdaConfiguration = 'eda::configuration',

  /** Allows the user to change the MAVIR BOSs password. */
  BossCredentialChange = 'boss::credential-change',

  /** Allows the user to use the EDA RSZ tender pricing feature. */
  RszTenderPricing = 'rsz::tender-pricing',

  /** Allows the user to use the RSZ tender report feature. */
  RszReport = 'rsz::report',

  /** Allows the user to use the HUPX DAM limit order feature. */
  HupxDamLimitOrder = 'hupxdam::limit-order',

  /** Allows the user to use the HUPX DAM final limit order feature. */
  HupxDamFinalLimitOrder = 'hupxdam::final-limit-order',

  /** Allows the user to use the HUPX DAM limit order feature. */
  HupxDamTenantPosition = 'hupxdam::tenant-position',

  /** Allows the user to use the HUPX DAM schedule generation feature (the final step). */
  HupxDamScheduleGeneration = 'hupxdam::schedule-generation',

  /** Allows the user to use the HUPX IDA limit order feature. */
  HupxIdaLimitOrder = 'hupxida::limit-order',

  /** Allows the user to use the HUPX IDA tenant position feature. */
  HupxIdaTenantPosition = 'hupxida::tenant-position',

  /** Allows the user to use the HUPX IDA final limit order feature. */
  HupxIdaFinalLimitOrder = 'hupxida::final-limit-order',

  /** Allows the user to use the MEK schedule modification feature. */
  MekModifySchedule = 'mek::modify-schedule',

  /** Allows the user to send EiA reports. */
  EiaReportSender = 'eia::report-sender',

  /** Allows the user to use the KDA offer feature. */
  KdaOffer = 'kda::offer',

  /** Allows the user to use the KDA offer report feature. */
  KdaReport = 'kda::report',

  /** Allows the user to use the KDA configuration feature. */
  KdaConfiguration = 'kda::configuration',

  /** Allows the user to use the RIR market feature. */
  RirMarket = 'rir::market',

  /** Allows the user to use the RIR report feature. */
  RirReport = 'rir::report',

  /** Allows the user to use the MEKI schedule repair feature. */
  MekRepairTool = 'mek::repair-tool',

  /** Allows the user to use the incident report feature. */
  SupportCreateIncident = 'support::create-incident',

  /** Allows the user to list workflow parameters and their details. */
  WorkflowParameterRead = 'workflow-parameter::read',

  /** Allows the user to use the workflow parameter feature. */
  WorkflowParameterWrite = 'workflow-parameter::write',

  /** Allows the user to use the Workflow Parameter label management feature. */
  WorkflowParameterLabelManagement = 'workflow-parameter::label-management',

  /** Allows list all tenants and get a selected tenant and all its relations by tenant identifier. */
  TenantRead = 'tenant::read',

  /** Allows the user to modify tenants through the API. */
  TenantWrite = 'tenant::write',
}
