import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Observable,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  of,
  tap,
} from 'rxjs';
import { getErrorMessage } from '../../../../shared/utils';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BannerContainerComponent } from '../banner-container/banner-container.component';
import { AssignValueOption } from '../../interfaces';
import { formatInBudapestTimeZone } from '../../../../shared/utils/dates';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * A general value assign dialog with correct assign UX behavior.
 *
 * The assign operation happens while the modal is open and any returned error is
 * displayed by the dialog itself and allows the user to retry the operation.
 *
 * The modal returns different data based on how the user closed it:
 * - when the user presses cancel without attempting to call the passed action, the component will return undefined as network response
 * - when the user presses cancel after a failed call attempt, the component will return the received error response
 * - when the user successfully calls the action, the component will return the success response
 *
 * Its IMPORTANT that the expected `assignActionFactory` must be correctly bound to it's source context
 * and should be able callable multiple times.
 */
@Component({
  selector: 'app-assign-value-dialog',
  templateUrl: './assign-value-dialog.component.html',
  styleUrls: ['./assign-value-dialog.component.scss'],
})
export class AssignValueDialogComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  /**
   * Indicates if the assign request is currently in progress or not.
   */
  public requestInFlight = false;

  /**
   * General banner container used by all views.
   */
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  /**
   * The available options to assign a value.
   */
  public options: Map<string, AssignValueOption> = new Map();

  /** The form group which contains the selected option and the validity range. */
  public formGroup = new FormGroup({
    valueControl: new FormControl<string>(this.data.pod?.code ?? '', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    rangeStart: new FormControl<Date | null>(this.data.pod?.validFrom ? new Date(this.data.pod.validFrom) : null, {
      validators: [Validators.required],
    }),
    rangeEnd: new FormControl<Date | null>(this.data.pod?.validUntil ? new Date(this.data.pod.validUntil) : null, {
      validators: [Validators.required],
    }),
  });

  /**
   * The filtered options by typed string.
   */
  public filteredOptions!: Observable<AssignValueOption[]>;

  /** The response from the latest (failed) delete call. */
  public latestNetworkResponse: HttpErrorResponse | undefined;

  /**
   * Contains all required error messages.
   */
  public getErrorMessage = getErrorMessage;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      /**
       * The function lists the available options.
       */
      optionsFactory: (value?: unknown) => Observable<Map<string, AssignValueOption>>;

      /**
       * The optional title of the modal window. Without it, there is a default title.
       */
      title?: string;

      /**
       * The optional description of the modal window. Without it, there is a default description.
       */
      description?: string;

      /**
       * The optional input label of the modal window. Without it, there is a default input label.
       */
      label?: string;

      /**
       * The optional selected pod. It contains the POD code, if we want to update an existed one,
       * it does not, if we want to create a new one.
       */
      pod?: { code?: string; validFrom: string; validUntil: string } | null;

      /**
       * The validator of the select list.
       */
      validator: (options: Map<string, AssignValueOption>) => ValidatorFn;

      /** The dynamic assign function. */
      assignActionFactory: (value: unknown) => Observable<HttpResponse<unknown>>;
    },
    public dialogRef: MatDialogRef<AssignValueDialogComponent>
  ) {}

  public ngOnInit(): void {
    if (this.data.pod?.code !== undefined) {
      this.formGroup.get('valueControl')?.disable();
    }

    this.requestInFlight = true;

    if (this.data.pod !== undefined) {
      combineLatest([
        this.formGroup.controls['rangeStart'].valueChanges.pipe(distinctUntilChanged()),
        this.formGroup.controls['rangeEnd'].valueChanges.pipe(distinctUntilChanged()),
      ])
        .pipe(
          debounceTime(200),
          tap(() => {
            this.formGroup.controls['rangeStart'].updateValueAndValidity();
            this.formGroup.controls['rangeEnd'].updateValueAndValidity();
          }),
          map(([rangeStart, rangeEnd]) => {
            this.data
              .optionsFactory({
                validFrom: rangeStart ? formatInBudapestTimeZone(rangeStart, 'yyyy-MM-dd') : undefined,
                validUntil: rangeEnd ? formatInBudapestTimeZone(rangeEnd, 'yyyy-MM-dd') : undefined,
              })
              .pipe(
                tap(options => {
                  this.options = options;
                  this.formGroup.get('valueControl')?.addValidators(this.data.validator(this.options));
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                  this.latestNetworkResponse = errorResponse;

                  this.bannerContainer.showApiError(errorResponse.error as Error, {
                    closable: false,
                  });

                  return of(null);
                }),
                finalize(() => (this.requestInFlight = false))
              )
              .subscribe();

            /**
             * Fills the filtered options using a typed character string.
             */
            this.filteredOptions = this.formGroup.controls['valueControl'].valueChanges.pipe(
              debounceTime(200),
              map(value => {
                return Array.from(this.options.values()).filter(option =>
                  option.text.toLowerCase().includes(value?.toString().toLowerCase())
                );
              }),
              takeUntilDestroyed(this.destroyRef)
            );
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();

      this.formGroup.patchValue({
        rangeStart: this.data.pod?.validFrom ? new Date(this.data.pod.validFrom) : new Date(),
        rangeEnd: this.data.pod?.validUntil ? new Date(this.data.pod.validUntil) : new Date(),
      });
    } else {
      this.data
        .optionsFactory()
        .pipe(
          tap(options => {
            this.options = options;
            this.formGroup.get('valueControl')?.addValidators(this.data.validator(this.options));
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.latestNetworkResponse = errorResponse;

            this.bannerContainer.showApiError(errorResponse.error as Error, {
              closable: false,
            });

            return of(null);
          }),
          finalize(() => (this.requestInFlight = false))
        )
        .subscribe();

      /**
       * Fills the filtered options using a typed character string.
       */
      this.filteredOptions = this.formGroup.controls['valueControl'].valueChanges.pipe(
        debounceTime(200),
        map(value => {
          return Array.from(this.options.values()).filter(option =>
            option.text.toLowerCase().includes(value?.toString().toLowerCase())
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      );
    }
  }

  /**
   * Checks if the request confirmed or not.
   *
   * @param response If the value is true, the request is confirmed, otherwise not.
   */
  public confirm(response: boolean): void {
    this.bannerContainer.clearAll();

    if (response) {
      if (this.data?.assignActionFactory !== undefined) {
        let assignObservable = undefined;

        const value = this.options.get(this.formGroup.get('valueControl')?.value as string)?.value as string;
        const validFrom = formatInBudapestTimeZone(this.formGroup.get('rangeStart')?.value as Date, 'yyyy-MM-dd');
        const validUntil = formatInBudapestTimeZone(this.formGroup.get('rangeEnd')?.value as Date, 'yyyy-MM-dd');

        if (this.data.pod === undefined) {
          assignObservable = this.data.assignActionFactory(value);
        } else if (this.data.pod?.code === undefined) {
          assignObservable = this.data.assignActionFactory({ value, validFrom, validUntil });
        } else {
          assignObservable = this.data.assignActionFactory({ validFrom, validUntil });
        }

        /** If the provided action factory is incorrect we show error immediately */
        if (assignObservable === undefined || assignObservable.subscribe === undefined) {
          this.bannerContainer.showError('Cannot assign resource.', {
            closable: false,
          });

          return;
        }

        this.requestInFlight = true;

        assignObservable
          .pipe(
            tap(result => {
              this.dialogRef.close({ success: true, response: result });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              this.latestNetworkResponse = errorResponse;

              this.bannerContainer.showApiError(errorResponse.error as Error, {
                closable: false,
              });

              return of(null);
            }),
            finalize(() => (this.requestInFlight = false))
          )
          .subscribe();
      } else {
        this.dialogRef.close(this.options.get(this.formGroup.get('valueControl')?.value as string)?.value);
      }
    } else {
      /**
       * Closes the dialog without executing the action.
       * If a failed action was executed prior to closing it is passed back to the caller.
       */
      this.dialogRef.close({
        success: false,
        response: this.latestNetworkResponse,
      });
    }
  }
}
